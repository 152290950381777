<template>
  <v-row class="pt-10">

  </v-row>
    <v-row align="center" justify="center" class="pt-10 mt-10">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark :color="store.NOTARIA.Theme.Footer.FooterCSS.BackgroundColor">
            <v-toolbar-title>Inicio de Sesión</v-toolbar-title>
          </v-toolbar>
          <v-alert type="error" v-if="errorLogin">
            Inicio de sesión incorrecto
          </v-alert>
          <v-card-text>
            <v-form>
              <v-text-field v-model="username" prepend-icon="mdi-account" name="usuario" label="Usuario"
                type="text"></v-text-field>
              <v-text-field v-model="password" id="password" prepend-icon="mdi-lock" name="password" label="Contraseña"
                type="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="login">
              Entrar
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>
</template>
<script setup lang="ts">
import { useStore } from '../store/index'
const store = useStore()
const show2 = ref(true)
const username = ref("")
const password = ref("")
const loading = ref(false)
const errorLogin = ref(false)

async function validate() {
  //console.log(this.password)
}

async function login() {
  var result = await store.doLogin({ username: username.value, password: password.value })
  if (result) {
    await navigateTo("/")
  }
  else
    errorLogin.value = true;

}
</script>