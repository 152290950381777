<template>
  <div>
    <div class="under-page-inner bg_color--1">
      <v-container v-if="NOTARIA">
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <!-- <h2 class="heading-title" :style="'color:'+NOTARIA.Theme.Footer.FooterCSS.BackgroundColor"> -->
                <v-icon
                  large
                >
                  mdi-account-hard-hat
                </v-icon>
              <!-- </h2> -->
              <h3 >Página en construcción</h3>
              <span >Estamos construyendo la nueva página web de {{NOTARIA.Nombre}}</span>
              <br>
              <span  v-if="!token">Acceda para ver los cambios</span>
                <Login v-if="!token"/>
              <div class="ma-6" v-if="token">
                <a href="https://controlpanel.notin.es" target="_blank">
                  Acceder al panel de control
                </a>
              </div>
              <div class="ma-6" v-if="token">
                <v-btn
                  class="rn-button-style-notin-1"
                  @click="store.doLogOut()" >
                  Salir
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '../store/index'
const store = useStore();
const { NOTARIA } = storeToRefs(store)
definePageMeta({
  layout: 'blank'
})
</script>